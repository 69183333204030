@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/mixins";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/variables";

.front{
    .section-1{
        @include container-wrap();

        .pane-bundle-text{
            &:first-of-type{
                text-align: center;
                @include breakpoint($md){
                    margin-left: 5rem;
                    margin-right: 5rem;
                }
            }
        }
    }
    .section-2{
        @include container-fixed();
        .section-wrapper{
            @include make-row();
        }
    }
    .section-3{
        @include container-fixed();

        .section-wrapper{
            @include make-row();
            background: $wcm-bg-gray;

            .pane-upcoming-events{

                .wcm-upcoming-events{
                    > .container{
                        margin-right: auto;

                        .wcm-upcoming-events-section{
                            margin-top: 0;

                            .wcm-upcoming-events-inner-section{

                                @include breakpoint($sm){
                                    display: flex;
                                }
                            
                                .wcm-upcoming-event-horizontal{
                                    background-color: $wcm-white;
                                    padding-top: 2rem;
                                    padding-bottom: 2rem;
    
                                    @include breakpoint($sm){
                                        margin-left: 1rem;
                                        margin-right: 1rem;
                                    
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
    .section-4{
        @include container-wrap();
        .section-wrapper{
            @include make-row();
            .col-1{
                @include make-xs-column(12);
                @include make-md-column(9);
            }
            .col-2{
                @include make-xs-column(12);
                @include make-md-column(3);
            }
        }
    }
    .section-5{
        @include container-wrap();

        div.panel-pane:nth-child(6){
            margin-top: 0;
        }

        hr{
            border-bottom: 1px solid $wcm-border-gray;
            margin-top: 0;
        }
    }

    .panel-pane:not(.pane-node-title):not(.pane-node-body){
        &.pane-bundle-quick-links{
            margin-top: 0;
        }
    }

    .twitter-timeline {
        width: 100vw!important;
    }

}