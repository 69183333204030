@charset "UTF-8";
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
.front .section-1 {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .front .section-1:before, .front .section-1:after {
    content: " ";
    display: table; }
  .front .section-1:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .front .section-1 {
      width: 750px; } }
  @media screen and (min-width: 992px) {
    .front .section-1 {
      width: 970px; } }
  @media screen and (min-width: 1200px) {
    .front .section-1 {
      width: 1170px; } }
  .front .section-1 .pane-bundle-text:first-of-type {
    text-align: center; }
    @media screen and (min-width: 992px) {
      .front .section-1 .pane-bundle-text:first-of-type {
        margin-left: 5rem;
        margin-right: 5rem; } }

.front .section-2 {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .front .section-2:before, .front .section-2:after {
    content: " ";
    display: table; }
  .front .section-2:after {
    clear: both; }
  .front .section-2 .section-wrapper {
    margin-left: -20px;
    margin-right: -20px; }
    .front .section-2 .section-wrapper:before, .front .section-2 .section-wrapper:after {
      content: " ";
      display: table; }
    .front .section-2 .section-wrapper:after {
      clear: both; }

.front .section-3 {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .front .section-3:before, .front .section-3:after {
    content: " ";
    display: table; }
  .front .section-3:after {
    clear: both; }
  .front .section-3 .section-wrapper {
    margin-left: -20px;
    margin-right: -20px;
    background: #f7f7f7; }
    .front .section-3 .section-wrapper:before, .front .section-3 .section-wrapper:after {
      content: " ";
      display: table; }
    .front .section-3 .section-wrapper:after {
      clear: both; }
    .front .section-3 .section-wrapper .pane-upcoming-events .wcm-upcoming-events > .container {
      margin-right: auto; }
      .front .section-3 .section-wrapper .pane-upcoming-events .wcm-upcoming-events > .container .wcm-upcoming-events-section {
        margin-top: 0; }
        @media screen and (min-width: 768px) {
          .front .section-3 .section-wrapper .pane-upcoming-events .wcm-upcoming-events > .container .wcm-upcoming-events-section .wcm-upcoming-events-inner-section {
            display: flex; } }
        .front .section-3 .section-wrapper .pane-upcoming-events .wcm-upcoming-events > .container .wcm-upcoming-events-section .wcm-upcoming-events-inner-section .wcm-upcoming-event-horizontal {
          background-color: #fff;
          padding-top: 2rem;
          padding-bottom: 2rem; }
          @media screen and (min-width: 768px) {
            .front .section-3 .section-wrapper .pane-upcoming-events .wcm-upcoming-events > .container .wcm-upcoming-events-section .wcm-upcoming-events-inner-section .wcm-upcoming-event-horizontal {
              margin-left: 1rem;
              margin-right: 1rem; } }

.front .section-4 {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .front .section-4:before, .front .section-4:after {
    content: " ";
    display: table; }
  .front .section-4:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .front .section-4 {
      width: 750px; } }
  @media screen and (min-width: 992px) {
    .front .section-4 {
      width: 970px; } }
  @media screen and (min-width: 1200px) {
    .front .section-4 {
      width: 1170px; } }
  .front .section-4 .section-wrapper {
    margin-left: -20px;
    margin-right: -20px; }
    .front .section-4 .section-wrapper:before, .front .section-4 .section-wrapper:after {
      content: " ";
      display: table; }
    .front .section-4 .section-wrapper:after {
      clear: both; }
    .front .section-4 .section-wrapper .col-1 {
      position: relative;
      float: left;
      width: 100%;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px;
      position: relative;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px; }
      @media (min-width: 992px) {
        .front .section-4 .section-wrapper .col-1 {
          float: left;
          width: 75%; } }
    .front .section-4 .section-wrapper .col-2 {
      position: relative;
      float: left;
      width: 100%;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px;
      position: relative;
      min-height: 1px;
      padding-left: 20px;
      padding-right: 20px; }
      @media (min-width: 992px) {
        .front .section-4 .section-wrapper .col-2 {
          float: left;
          width: 25%; } }

.front .section-5 {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .front .section-5:before, .front .section-5:after {
    content: " ";
    display: table; }
  .front .section-5:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .front .section-5 {
      width: 750px; } }
  @media screen and (min-width: 992px) {
    .front .section-5 {
      width: 970px; } }
  @media screen and (min-width: 1200px) {
    .front .section-5 {
      width: 1170px; } }
  .front .section-5 div.panel-pane:nth-child(6) {
    margin-top: 0; }
  .front .section-5 hr {
    border-bottom: 1px solid #dddddd;
    margin-top: 0; }

.front .panel-pane:not(.pane-node-title):not(.pane-node-body).pane-bundle-quick-links {
  margin-top: 0; }

.front .twitter-timeline  {
  width: 100vw !important; }
